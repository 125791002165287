<template>
  
  <div>
   <Header></Header>
      <Doseach/>
   <component :is="comName"> </component>
  </div>
</template>

<script>
import Doseach from './DoSeach'
import Header from "@/components/Header";
import attribute from "./attribute";
import forecast from "./forecast";
import radar3 from "./radar3";
import radar_fist from "./radar_fist";
export default {
  data() { 
    return {
  comName:"attribute",
    }
  
  },
  watch: {
    '$store.state.componentName': {
      handler(newval) { 
        console.log('componentName :>> ', newval);
       
        if (this.$store.state.radarRole == false) {
          if (newval == 'panel') {
            this.comName = 'attribute'
          } else {
            this.comName = 'forecast'
          }
        } else { 
          if (newval == 'panel') {
            this.comName = 'radar_fist'
          } else {
            this.comName = 'radar3'
          }
        }
      
      }
     
    }
    ,
    '$store.state.radarRole': {
      handler(newval) { 
        console.log('newval :>> ', newval);
        if (this.$store.state.radarRole == false) {
          if (this.$store.state.componentName == "panel") {
            this.comName = 'attribute'
          } else {
            this.comName = "forecast"
          }
        } else { 
             if (this.$store.state.componentName == "panel") {
            this.comName = 'radar_fist'
          } else {
            this.comName = "radar3"
          }
        }
      }
    }
  },
  components: {
    Doseach,
    Header,
    attribute,
    forecast,
    radar3,
    radar_fist

  }
,
 
}
</script>

<style>

</style>